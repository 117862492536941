import * as React from "react"
import Layout from "../components/Layout"
import 'reset-css';
import './index.css';
import styled from "styled-components";

const IndexPage = () => {
  return (
    <Layout>
      <UnderConstruction>
        <h1>Coming Soon</h1>
      </UnderConstruction>
    </Layout>
  )
}

export default IndexPage

const UnderConstruction = styled.div`
  margin-top: 50px;
  h1 {
    font-size: 3rem;
    font-weight: 700;
    max-width: 600px;
  }
`